













































import { Vue, Component } from "vue-property-decorator";
import { UserRegistrationModule as userStore } from "@/store/modules";
import DetailTag from "@/components/UIComponents/DetailTag.vue";
import { AdminRouter } from "@/utils/routePathContsant";

@Component({
  components: {
    DetailTag,
  },
})
export default class PublicUserDetail extends Vue {
  async created() {
    const id = this.$route.query["id"];
    await userStore.loadPublicUserById(id);
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get publicUser() {
    return userStore.publicUser;
  }
}
